import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from 'react-router'
import CollectionForm from '../../forms/collection-form/CollectionForm'
import {createCollection} from "../../../../redux/actions/index";
import './NewCollectionPage.css'
class NewCollectionPage extends Component
{

  constructor(props) {
    super(props);
    this.state = {
      collectionCreatedSuccessfully: false
    }
  };

  onSubmit = (collection) => {
    console.log("Submitted on new collection page: ", collection);
    this.props.createCollection(collection).then(() => {
      this.setState({
        collectionCreatedSuccessfully: true
      });
    });
  }

    render() {
        if (this.state.collectionCreatedSuccessfully) {
          return <Redirect to='/collections'/>
        }
        return(
            <div id="new-collection-page-container">
              <h1 id="page-title">New Collection</h1>
              <div id="form-container">
                <CollectionForm onSubmit={this.onSubmit} editMode={false}/>
              </div>
            </div>
        )
    }
}

// const mapStateToProps = state => {
//   return {
//     collectionCreatedSuccessfully: state.collectionCreatedSuccessfully
//   };
// };

const mapDispatchToProps = dispatch => {
  return {
    createCollection: collection => dispatch(createCollection(collection))
  }
}

export default connect(null, mapDispatchToProps)(NewCollectionPage)
