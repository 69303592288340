export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const SET_CURRENT_COLLECTION = "SET_CURRENT_COLLECTION";

export const GET_SCENES = "GET_SCENES";
export const CREATE_SCENE = "CREATE_SCENE";
export const DELETE_SCENE = "DELETE_SCENE";
export const UPDATE_SCENE = "UPDATE_SCENE";
export const SET_SELECTED_SCENE = "SET_SELECTED_SCENE";

export const GET_LINKS = "GET_LINKS";
export const CREATE_LINK = "CREATE_LINKS";
export const DELETE_LINK = "DELETE_LINKS";
export const UPDATE_LINK = "UPDATE_LINKS";
export const SET_SELECTED_LINK = "SET_SELECTED_LINKS";

export const SET_SAVING = "SET_SAVING";
