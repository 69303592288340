import React, {Component} from 'react';
import { connect } from "react-redux";
import {getCollections} from "../../../../redux/actions/index";

import './CollectionsPage.css'
import {
  Form,
  Button,
  Icon,
  Modal,
  Image,
  Header,
  Segment,
  Transition,
  List,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import { Scrollbars } from 'react-custom-scrollbars';
import CollectionCard from './collection-card/CollectionCard'
import InfoMessage from '../../common/info-message/InfoMessage'

class CollectionsPage extends Component {

  constructor(props) {
    super(props);
    if (!this.props.collectionsLoaded) {
      this.props.getCollections();
    }
  }

  renderCollectionCards = () => {
    if (this.props.collectionsLoaded) {
      const collectionsAlphabeticalOrder = this.props.collections.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        else { return 0; }
      });
      return collectionsAlphabeticalOrder.map((collection) => <CollectionCard key={collection.id} collection={collection}/>);
    }
  }

  renderLoadingScreen = () => {
    if (!this.props.collectionsLoaded) {
      return (<Dimmer active id="dimmer">
        <Loader>Loading collections</Loader>
      </Dimmer>)
    }
  }

  renderThumbVertical = ({ style, ...props }) => {
     return <div {...props} style={{ ...style, backgroundColor: '#e0e1e2', width: '8px', borderRadius: '10px', cursor: 'pointer'}}/>
  }

  renderContent = () => {
    console.log(this.props.collectionsLoaded ? "collections loaded" : "collections not loaded");
    if (this.props.collectionsLoaded) {
      if (this.props.collections.length === 0) {
        return <div id="no-collections-message-container"><InfoMessage>There are no existing collections.</InfoMessage></div>
      } else {
        return <div id="scroll-container">
          <Scrollbars hideTracksWhenNotNeeded={true} renderThumbVertical={this.renderThumbVertical}>
            {this.renderLoadingScreen()}
            <div id="collection-cards-container">
              {this.renderCollectionCards()}
            </div>
          </Scrollbars>
        </div>
      }
    }
  }

  render() {
    return (
      <div id="collections-page-container">
        <h1 id="page-title">Collections</h1>
        {this.renderContent()}
      </div>
    )
  }
};

const mapStateToProps = state => {
  return {
    collections: state.collections,
    collectionsLoaded: state.collectionsLoaded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCollections: () => dispatch(getCollections())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPage)
