import React, {Component} from 'react';
import './EditLinkDetailsPopup.css'
import {Button, Modal, Form, TextArea, Icon, Image, TransitionablePortal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {updateLink} from "../../../../../../../../redux/actions/index";
import LinkForm from '../../../../../../forms/link-form/LinkForm'
import Popup from '../../../../../../common/popup/Popup'

class EditLinkDetailsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }
  }

  onSubmit = (link) => {
    this.props.updateLink(link, this.props.currentCollection).then(() => {
      this.setState({
        ...this.state,
        showPopup: false
      });
    });
  }

  handleOpen = () => {
    this.setState({ showPopup: true })
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  }

  renderTriggerButton = () => {
    return <span onClick={this.handleOpen}>{this.props.children}</span>
  }

  renderContent = () => {
    return <span>
      <LinkForm link={this.props.link} onSubmit={this.onSubmit} editMode={true}/>
    </span>
  }

  renderActions = () => {
    return <span>
      <span className="action-button-container">
        <Button icon labelPosition='left' onClick={this.handleClose}><Icon name='cancel'/>Cancel</Button>
        </span>
        <span className="action-button-container">
        <Button icon labelPosition='left' type="submit" form="link-form"><Icon name='save'/>Save</Button>
        </span>
      </span>
  }

  render() {
    return (
      <Popup
        width={400}
        height={220}
        showPopup={this.state.showPopup}
        header="Edit Details"
        renderTriggerButton={this.renderTriggerButton}
        renderContent={this.renderContent}
        renderActions={this.renderActions}></Popup>
        )}
}

const mapDispatchToProps = dispatch => {
  return {
    updateLink: (link, collection) => dispatch(updateLink(link, collection))
  }
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLinkDetailsPopup)
