import React, {Component} from 'react';
import './EditCollectionPopup.css'
import {Button, Modal, Form, TextArea, Icon, Image, TransitionablePortal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {updateCollection} from "../../../../../redux/actions/index";
import CollectionForm from '../../../forms/collection-form/CollectionForm'
import Popup from '../../../common/popup/Popup'

class EditCollectionPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }
  }

  onSubmit = (collection) => {
    this.props.updateCollection(collection).then(() => {
      this.setState({
        ...this.state,
        showPopup: false
      });
    });
  }

  handleOpen = () => {
    this.setState({ showPopup: true })
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  }

  renderTriggerButton = () => {
    return <Button icon labelPosition='left' onClick={this.handleOpen}><Icon name="edit"/>Edit Details</Button>
  }

  renderContent = () => {
    return <span>
      <CollectionForm collection={this.props.collection} onSubmit={this.onSubmit} editMode={true}/>
    </span>
  }

  renderActions = () => {
    return <span>
      <span className="action-button-container">
        <Button icon labelPosition='left' onClick={this.handleClose}><Icon name='cancel'/>Cancel</Button>
        </span>
        <span className="action-button-container">
        <Button icon labelPosition='left' type="submit" form="collection-form"><Icon name='save'/>Save</Button>
        </span>
      </span>
  }

  render() {
    return (
      <Popup
        width={800}
        height={620}
        showPopup={this.state.showPopup}
        header="Edit Details"
        renderTriggerButton={this.renderTriggerButton}
        renderContent={this.renderContent}
        renderActions={this.renderActions}></Popup>
        )}
}

const mapDispatchToProps = dispatch => {
  return {
    updateCollection: collection => dispatch(updateCollection(collection))
  }
}

export default connect(null, mapDispatchToProps)(EditCollectionPopup)
