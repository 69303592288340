import React, { Component } from 'react';

import styles from './LinkList.module.css';

import {
  Segment,
  List
} from 'semantic-ui-react'

import LinkListItem from './link-list-item/LinkListItem';

import InfoMessage from '../../../../common/info-message/InfoMessage';

import { connect } from "react-redux";
import { getLinks } from "../../../../../../redux/actions/index";

class LinkList extends Component {

  constructor(props) {
    super(props);
    if (!props.currentCollection || !props.selectedScene) { return }
    this.props.getLinks(props.selectedScene, props.currentCollection, props.scenes);
  }

  render() {
    return this.props.linksLoaded ? (
        <div className={styles.LinkList}>
        <Segment className={styles.LinkListSegment}>
          <div className={styles.SegmentContent}>
            <h4>Links</h4>
            { this.props.links.length > 0 ? (
              <List>{this.props.links.map(link => {
                  // Adds a separator after the list item, unless it is the last one.
                  return (this.props.links.indexOf(link) < this.props.links.length - 1) ? (
                    <React.Fragment key={link.from.navigationID}>
                      <LinkListItem link={link}/>
                      <div className={styles.LinkListSeparator}></div>
                    </React.Fragment>
                  ) : <LinkListItem key={link.from.navigationID} link={link}/>
                })}</List>
            ) : (
              <div className={styles.InfoMessageContainer}>
                <InfoMessage>There are no existing links.</InfoMessage>
              </div>
            )}
            </div>
          </Segment>
        </div>
      ) : null;
    }
  }

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection,
    selectedScene: state.selectedScene,
    scenes: state.scenes,
    links: state.links,
    linksLoaded: state.linksLoaded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLinks: (scene, collection, allScenes) => dispatch(getLinks(scene, collection, allScenes))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkList);
