import {
  GET_COLLECTIONS,
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  UPDATE_COLLECTION,
  SET_CURRENT_COLLECTION,

  GET_SCENES,
  CREATE_SCENE,
  DELETE_SCENE,
  UPDATE_SCENE,
  SET_SELECTED_SCENE,

  GET_LINKS,
  CREATE_LINK,
  DELETE_LINK,
  UPDATE_LINK,
  SET_SELECTED_LINK,

  SET_SAVING
} from "../constants/action-types";

import api from '../../api/index';

export const getCollections = () => {
  return function(dispatch) {
    return api.getCollections().then((response) => {
      dispatch({ type: GET_COLLECTIONS, payload: response.data });
    }).catch(error => {
      throw(error);
    });
  }
};

export const createCollection = (collection) => {
  return function(dispatch) {
    return api.createCollection(collection).then((response) => {
      dispatch({ type: CREATE_COLLECTION, payload: response.data });
    }).catch(error => {
      throw(error);
    });
  }
};

export const deleteCollection = (collection) => {
  return function(dispatch) {
    return api.deleteCollection(collection.id).then(() => {
      dispatch({ type: DELETE_COLLECTION, payload: collection });
    }).catch(error => {
      throw(error);
    });
  }
};

export const updateCollection = (collection) => {
  return function(dispatch) {
    return api.updateCollection(collection).then((response) => {
      dispatch({ type: UPDATE_COLLECTION, payload: response.data });
    }).catch(error => {
      throw(error);
    });
  }
};

export const setCurrentCollection = (collection) => ({ type: SET_CURRENT_COLLECTION, payload: collection });

export const getScenes = (collection) => {
  return function(dispatch) {
    return api.getScenes(collection.id).then((response) => {
      dispatch({ type: GET_SCENES, payload: response.data });
    }).catch(error => {
      throw(error);
    });
  }
};

export const createScene = (scene, collection) => {
  return function(dispatch) {
    return api.createScene(scene, collection.id).then((response) => {
      dispatch({ type: CREATE_SCENE, payload: response.data });
    }).catch(error => {
      throw(error);
    });
  }
};

export const deleteScene = (scene, collection) => {
  return function(dispatch) {
    return api.deleteScene(scene.id, collection.id).then((response) => {
      dispatch({ type: DELETE_SCENE, payload: scene });
    }).catch(error => {
      throw(error);
    });
  }
};

export const updateScene = (scene, collection) => {
  return function(dispatch) {
    return api.updateScene(scene, collection.id).then((response) => {
      dispatch({ type: UPDATE_SCENE, payload: response.data });
    }).catch(error => {
      throw(error);
    });
  }
};

export const setSelectedScene = (scene) => ({ type: SET_SELECTED_SCENE, payload: scene });

export const setSaving = () => ({ type: SET_SAVING });

export const getLinks = (scene, collection, allScenes) => {
  return function(dispatch) {
    return api.getNavigations(scene.id, collection.id).then((response) => {

      const navigations = response.data;
      const fromNavigations = navigations.filter(navigation => navigation.from_scene_id === scene.id);
      const toNavigations = navigations.filter(navigation => navigation.to_scene_id === scene.id);

      const links = [];

      fromNavigations.map(from => {
        const to = toNavigations.find(to => to.name === from.name);

        links.push({
          name: from.name,
          from: {
            navigationID: from.id,
            scene: scene,
            position: {
              x: JSON.parse(from.position)[0],
              y: JSON.parse(from.position)[1]
            }
          },
          to: {
            navigationID: to.id,
            scene: allScenes.find(scene => scene.id === to.from_scene_id),
            position: {
              x: JSON.parse(to.position)[0],
              y: JSON.parse(to.position)[1]
            }
          }
        })
      });

      dispatch({ type: GET_LINKS, payload: links });

    }).catch(error => {
      throw(error);
    });
  }
};

export const createLink = (link, collection) => {
  return function(dispatch) {
    const forwardNavigation = {
      name: link.name,
      toSceneID: link.to.scene.id,
      position: link.from.position
    };
    return api.createNavigation(forwardNavigation, link.from.scene.id, collection.id).then((response1) => {
      const reverseNavigation = {
        name: link.name,
        toSceneID: link.from.scene.id,
        position: link.to.position
      };
      return api.createNavigation(reverseNavigation, link.to.scene.id, collection.id).then((response2) => {
        //Attach the returned ID and created/updated times to the initial link so it can be passed forward as the payload.
        const payload = {...link};
        payload.id = response1.data.id;
        payload.from.navigationID = response1.data.id;
        payload.to.navigationID = response2.data.id
        payload.createdAt = response1.data.created_at;
        payload.updatedAt = response1.data.updated_at;
        dispatch({ type: CREATE_LINK, payload: payload });
      }).catch(error => {
        throw(error);
      });
    }).catch(error => {
      throw(error);
    });
  }
};

export const updateLink = (link, collection) => {
  return function(dispatch) {
    const forwardNavigation = {
      id: link.from.navigationID,
      name: link.name,
      toSceneID: link.to.scene.id,
      position: link.from.position
    };
    return api.updateNavigation(forwardNavigation, link.from.scene.id, collection.id).then((response) => {
      const reverseNavigation = {
        id: link.to.navigationID,
        name: link.name,
        toSceneID: link.from.scene.id,
        position: link.to.position
      };
      return api.updateNavigation(reverseNavigation, link.to.scene.id, collection.id).then((_) => {
        //Attach the returned ID and created/updated times to the initial link so it can be passed forward as the payload.
        const payload = {...link};
        payload.id = response.data.id;
        payload.createdAt = response.data.created_at;
        payload.updatedAt = response.data.updated_at;
        dispatch({ type: UPDATE_LINK, payload: payload });
      }).catch(error => {
        throw(error);
      });
    }).catch(error => {
      throw(error);
    });
  }
};

export const deleteLink = (link, collection) => {
  return function(dispatch) {
    return api.deleteNavigation(link.from.navigationID, link.from.scene.id, collection.id).then((response) => {
      return api.deleteNavigation(link.to.navigationID, link.to.scene.id, collection.id).then((response) => {
        dispatch({ type: DELETE_LINK, payload: link });
      }).catch(error => {
        throw(error);
      });
    }).catch(error => {
      throw(error);
    });
  }
};

export const setSelectedLink = (link) => ({ type: SET_SELECTED_LINK, payload: link });
