import {
  GET_COLLECTIONS,
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  UPDATE_COLLECTION,
  SET_CURRENT_COLLECTION,

  GET_SCENES,
  CREATE_SCENE,
  DELETE_SCENE,
  UPDATE_SCENE,
  SET_SELECTED_SCENE,

  GET_LINKS,
  CREATE_LINK,
  DELETE_LINK,
  UPDATE_LINK,
  SET_SELECTED_LINK,

  SET_SAVING
} from "../constants/action-types";

const initialState = {
  collections: [],
  currentCollection: null,

  selectedScene: null,
  scenes: [],
  scenesLoaded: false,

  saving: false,

  selectedLink: null,
  links: [
    {
      name: "link1",
      from: {
        navigationID: -1,
        scene: {},
        position: {
          x: 50,
          y: 50
        }
      },
      to: {
        navigationID: -1,
        scene: {},
        position: {
          x: 50,
          y: 50
        }
      }
    },
    {
      name: "link2"
    },
    {
      name: "link3"
    }],
  linksLoaded: false
};

const sortAlphabeticalByName = list => {
  return list.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      else { return 0; }
    }
  );
}

const newStateGetCollections = (state, action) => {
  return { ...state, collections: action.payload, collectionsLoaded: true };
}

const newStateCreateCollection = (state, action) => {
  return{ ...state, collections: [...state.collections, action.payload] };
}

const newStateDeleteCollection = (state, action) => {
  return { ...state, collections: state.collections.filter(collection => collection.id !== action.payload.id)};
}

const newStateUpdateCollection = (state, action) => {
  const index = state.collections.findIndex(collection => collection.id === action.payload.id);
  const collections = [...state.collections];
  collections[index] = action.payload;
  return { ...state, collections: collections };
}

const newStateSetCurrentCollection = (state, action) => {
  return { ...state, currentCollection: action.payload, scenesLoaded: false };
}

const newStateGetScenes = (state, action) => {
  const orderedScenes = sortAlphabeticalByName(action.payload);

  let selectedScene = null;
  // If the currently selected scene belongs to the current collection, leave it as the selected scene.
  if (state.selectedScene && state.currentCollection.id === state.selectedScene.collection_id) {
    selectedScene = state.selectedScene;
  } else if (orderedScenes.length > 0) {
    selectedScene = orderedScenes[0];
  }

  // Gets the list of scenes, sets that the scenes have been loaded, and sets the selected scene as the first scene in the list (or null if there are no scenes).
  return { ...state, scenes: orderedScenes, scenesLoaded: true, selectedScene: selectedScene };
}

const newStateCreateScene = (state, action) => {
  const orderedScenes = sortAlphabeticalByName([...state.scenes, action.payload]);
  // Adds the created scene to the list of scenes in the state and sets the new scene as the currently selected scene if there is no currently selected scene already.
  return { ...state, scenes: orderedScenes, selectedScene: state.selectedScene ? state.selectedScene : action.payload, saving: false };
}

const newStateDeleteScene = (state, action) => {
  // Removes the deleted scene from the list of scenes.
  const newScenes = state.scenes.filter(scene => scene.id !== action.payload.id);
  const newSelectedScene = newScenes.length > 0 ? newScenes[0] : null;
  return { ...state, scenes: newScenes, selectedScene: newSelectedScene, saving: false};
}

const newStateSetSelectedScene = (state, action) => {
  // Sets the given scene as the currently selected scene.
  return { ...state, selectedScene: action.payload, linksLoaded: false };
}

const newStateUpdateScene = (state, action) => {
  const index = state.scenes.findIndex(scene => scene.id === action.payload.id);
  const scenes = [...state.scenes];
  scenes[index] = action.payload;
  const orderedScenes = sortAlphabeticalByName(scenes);
  return { ...state, scenes: orderedScenes, saving: false };
}

const newStateGetLinks = (state, action) => {
  // Gets the list of links, sets that the links have been loaded, and sets the selected link as the first link in the list (or null if there are no links).
  return { ...state, links: action.payload, linksLoaded: true, selectedLink: action.payload.length > 0 ? action.payload[0] : null };
}

const newStateCreateLink = (state, action) => {
  // Adds the created link to the list of links in the state and sets the new link as the currently selected link if there is no currently selected link already.
  return { ...state, links: [...state.links, action.payload], selectedLink: state.selectedLink ? state.selectedLink : action.payload, saving: false };
}

const newStateSetSelectedLink = (state, action) => {
  // Sets the given link as the currently selected link.
  return { ...state, selectedLink: action.payload };
}

const newStateUpdateLink = (state, action) => {
  const index = state.links.findIndex(link => (link.from.navigationID === action.payload.from.navigationID && link.to.navigationID === action.payload.to.navigationID));
  const links = [...state.links];
  links[index] = action.payload;
  return { ...state, links: links, selectedLink: action.payload, saving: false };
}

const newStateDeleteLink = (state, action) => {
  // Removes the deleted link from the list of links.
  const newLinks = state.links.filter(link => ((link.from.navigationID !== action.payload.from.navigationID) && (link.to.navigationID !== action.payload.to.navigationID) && (link.name !== action.payload.name)));
  const newSelectedLink = newLinks.length > 0 ? newLinks[0] : null;
  return { ...state, links: newLinks, selectedLink: newSelectedLink, saving: false};
}

const newStateSetSaving = (state, action) => {
  return { ...state, saving: true };
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTIONS:
      return newStateGetCollections(state, action);
    case CREATE_COLLECTION:
      return newStateCreateCollection(state, action)
    case DELETE_COLLECTION:
      return newStateDeleteCollection(state, action);
    case UPDATE_COLLECTION:
      return newStateUpdateCollection(state, action);
    case SET_CURRENT_COLLECTION:
      return newStateSetCurrentCollection(state, action);
    case GET_SCENES:
      return newStateGetScenes(state, action);
    case CREATE_SCENE:
      return newStateCreateScene(state, action);
    case DELETE_SCENE:
      return newStateDeleteScene(state, action);
    case UPDATE_SCENE:
      return newStateUpdateScene(state, action);
    case SET_SELECTED_SCENE:
      return newStateSetSelectedScene(state, action);
    case GET_LINKS:
      return newStateGetLinks(state, action);
    case CREATE_LINK:
      return newStateCreateLink(state, action);
    case DELETE_LINK:
       return newStateDeleteLink(state, action);
    case UPDATE_LINK:
       return newStateUpdateLink(state, action);
    case SET_SELECTED_LINK:
      return newStateSetSelectedLink(state, action);
    case SET_SAVING:
      return newStateSetSaving(state);
    default:
      return state;
  }
};

export default rootReducer;
