import React, { Component } from 'react';

import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox
} from 'semantic-ui-react'

import styles from './ManageLinks.module.css';

import GeneralOptions from './general-options/GeneralOptions';

import LinkList from './link-list/LinkList';
import LinkSelection from './link-selection/LinkSelection';

import { connect } from "react-redux";

class ManageLinks extends Component {

  render() {
    return(
      <div className={styles.ManageLinks}>
        <LinkList />
        <LinkSelection />
        <GeneralOptions></GeneralOptions>
      </div>
    )
  }
}

export default ManageLinks;
