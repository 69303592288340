import React, {Component} from 'react';
import { FilePicker } from 'react-file-picker';
import styles from './LinkForm.module.css';

import {connect} from "react-redux";

import {
  Form,
  Button,
  TextArea,
  Dropdown,
  Dimmer,
  Loader,
  Icon,
  Segment,
  Input,
  Message
} from 'semantic-ui-react';

class LinkForm extends Component {

  constructor(props) {
    super(props);
    const emptyLink = {
      name: "",
      from: {
        scene: props.selectedScene,
        position: {
          x: 0,
          y: 0
        }
      },
      to: {
        scene: null,
        position: {
          x: 0,
          y: 0
        }
      }
    };
    this.state = {
      dropdownOptions: [],
      link: this.props.link ? this.props.link : emptyLink,
      submitted: false,
      errorMessages: {
        nameErrorMessage: null
      }
    };

    //Bind the form change and submit functions so that this.state can be accessed in the functions.
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event, data) {
    this.setState({link: {...this.state.link, [data.id]: data.value}});
  }

  handleDropdownChange(event, data) {
    const link = {...this.state.link};
    link.to.scene = this.props.scenes.find(scene => scene.id === data.value);
    this.setState({link: link});
    console.log('state', this.state);
  }

  nameIsValid = name => {
    //Ensure that the field is not empty.
    if (name.length === 0) {
      this.setState({errorMessages: {
        nameErrorMessage: "A name for the link is required."
      }});
      return false;
    }

    //Ensure a link with the given name does not already exist.
    const links = this.props.links;
    let valid = true;
    for (let i = 0; i < links.length; i++) {
      if (links[i].name === name) {
        this.setState({errorMessages: {
          nameErrorMessage: "A link with the given name already exists."
        }});
        valid = false;
        break;
      }
    };
    return valid;
  }

  handleSubmit(event) {
    if (!this.nameIsValid(this.state.link.name)) {
      return;
    }
    this.setState({submitted: true});

    // Calculate the centre positions for both scenes of the link.
    const link = {...this.state.link};
    link.from.position = this.calculateCenterPosition(link.from.scene.image);
    link.to.position = this.calculateCenterPosition(link.to.scene.image);

    this.props.onSubmit(this.state.link);
  }

  calculateCenterPosition = image => {
    // Get the size of the original image.
    const originalImage = new Image();
    originalImage.src = image;
    console.log('owh', originalImage.width, originalImage.height);
    return {
      x: Math.round(originalImage.width / 2),
      y: Math.round(originalImage.height / 2)
    };
  }

  renderLoadingScreen = () => {
    if (this.state.submitted) {
      return (<Dimmer active id="dimmer">
        <Loader>{this.props.editMode ? "Saving link" : "Creating link"}</Loader>
      </Dimmer>)
    }
  }

  renderErrorMessage = (errorMessage) => {
    if (errorMessage) {
      return <Message color='red' id="nameErrorMessage">{errorMessage}</Message>
    }
  }

  convertScenesToDropdownOptions = scenes => {
    const options = [];
    scenes.map(scene => {
      if (scene.id !== this.props.selectedScene.id) {
        options.push({
          text: scene.name,
          value: scene.id,
          image: { avatar: true, src: scene.image }
        });
      }
    });
    return options;
  }

  componentWillMount() {
    this.setState({
      dropdownOptions: this.convertScenesToDropdownOptions(this.props.scenes)
    });
  }

  render() {
    return (
      <Form id="link-form" autoComplete="off" onSubmit={this.handleSubmit}>

        {this.renderLoadingScreen()}

        <Form.Field className={styles.FormItem} id="name-field">
          <label className={styles.Text}>Name</label>
          <Input id="name" autoComplete="off" value={this.state.link.name} onChange={this.handleNameChange}/>
        </Form.Field>

        {this.props.editMode ? (null) : (
          <Form.Field className={styles.FormItem} id="to-scene-field">
            <label className={styles.Text}>Scene to Link to</label>
            <Dropdown id="to-scene" fluid selection options={this.state.dropdownOptions} onChange={this.handleDropdownChange} />
          </Form.Field>
        )}

        {this.renderErrorMessage(this.state.errorMessages.nameErrorMessage)}

      </Form>)
  }
}

const mapStateToProps = state => {
  return {
    scenes: state.scenes,
    links: state.links,
    selectedScene: state.selectedScene
  };
};

export default connect(mapStateToProps, null)(LinkForm)
