import React, {Component} from 'react';
import {Button, Modal, Form, TextArea, Icon, Image, TransitionablePortal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {createLink} from "../../../../../../../redux/actions/index";
import LinkForm from '../../../../../forms/link-form/LinkForm'
import Popup from '../../../../../common/popup/Popup'

class NewLinkPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }
  }

  onSubmit = (link) => {
    this.props.createLink(link, this.props.currentCollection).then(() => {
      this.setState({
        ...this.state,
        showPopup: false
      });
    });
  }

  handleOpen = () => {
    this.setState({ showPopup: true })
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  }

  renderTriggerButton = () => {
    return <Button fluid icon labelPosition='left' onClick={this.handleOpen}><Icon name='add'/><div className="centred-button-text">New Link</div></Button>
  }

  renderContent = () => {
    return <span>
      <LinkForm link={this.props.link} onSubmit={this.onSubmit}/>
    </span>
  }

  renderActions = () => {
    return <span>
      <span className="action-button-container">
        <Button icon labelPosition='left' onClick={this.handleClose}><Icon name='cancel'/>Cancel</Button>
        </span>
        <span className="action-button-container">
        <Button icon labelPosition='left' type="submit" form="link-form"><Icon name='add'/>Create</Button>
        </span>
      </span>
  }

  render() {
    return (
      <Popup
        width={400}
        height={300}
        showPopup={this.state.showPopup}
        header="New Link"
        renderTriggerButton={this.renderTriggerButton}
        renderContent={this.renderContent}
        renderActions={this.renderActions}></Popup>
        )}
}

const mapDispatchToProps = dispatch => {
  return {
    createLink: (link, collection) => dispatch(createLink(link, collection))
  }
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLinkPopup)
