import React, {Component} from 'react';
import './SceneOptions.css'
import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox
} from 'semantic-ui-react'
import {connect} from "react-redux";
import {deleteScene, updateScene} from "../../../../../redux/actions/index";
import DeletePopup from '../../../common/delete-popup/DeletePopup';
import EditSceneDetailsPopup from './edit-scene-details-popup/EditSceneDetailsPopup';
import { Redirect } from 'react-router';

class SceneOptions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      toManageLinks: false
    }
  }

  manageLinksButtonPressed = () => {
    this.setState({toManageLinks: true});
  }

  onDelete = (collection) => {
    this.setState({
      ...this.state,
      sceneBeingDeleted: true
    });
    this.props.deleteScene(this.props.selectedScene, this.props.currentCollection).then(() => {
      this.setState({
        ...this.state,
        sceneBeingDeleted: false
      });
    });
  }

    render() {
      if (this.state.toManageLinks) {
        return <Redirect to='/manage-links' />
      }
        return(
          <Segment id="scene-options-segment">
            <div id="scene-options-segment-content">
              <div className="segment-title-container">
                <h4>Scene Options</h4>
              </div>
              <div className="scene-options-buttons-container">
                <EditSceneDetailsPopup disabled={!this.props.selectedScene} scene={this.props.selectedScene}></EditSceneDetailsPopup>
                <Button disabled={!this.props.selectedScene} onClick={this.manageLinksButtonPressed} icon labelPosition='left'><Icon name='linkify'/>Manage Links</Button>
                <Button disabled={!this.props.selectedScene || true} icon labelPosition='left'><Icon name='info'/>Manage Info Points</Button>
                <Button disabled={!this.props.selectedScene || true} icon labelPosition='left'><Icon name='map outline'/>Set Location on Map</Button>
                <Button disabled={!this.props.selectedScene || true} icon labelPosition='left'><Icon name='flag outline'/>Set as Entry Scene</Button>
                <DeletePopup disabled={!this.props.selectedScene} subject="scene" name={this.props.selectedScene ? this.props.selectedScene.name : ""} onDelete={this.onDelete}></DeletePopup>
              </div>
            </div>
        </Segment>
        )
    }
}

const mapStateToProps = state => {
  return {
    selectedScene: state.selectedScene,
    currentCollection: state.currentCollection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteScene: (scene, collection) => dispatch(deleteScene(scene, collection)),
    updateScene: (scene, collection) => dispatch(updateScene(scene, collection))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SceneOptions)
