import React, {Component} from 'react';
import './Header.css'
import {Form, Button, Icon, Image} from 'semantic-ui-react';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import Particles from 'react-particles-js';
import windowSize from 'react-window-size';
class Header extends Component {

  renderParticles = () => {
    return <Particles width={this.props.windowWidth} height={60} id="particles" params={{
        "particles" : {
          "number": {
            "value": 75
          },
          "size": {
            "value": 2,
            "random": true,
            "anim": {
              "enable": true,
              "speed": 0,
              "size_min": 0.1,
              "sync": false
            }
          },
          "move": {
            "enable": true,
            "speed": 1
          },
          "line_linked": {
            "enable": true,
            "distance": 100,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
        }
      }}/>
  }

  render() {
    return (<div id="header">
    <div id="title-container">
      <p id="zag-title" className="logo-text">
        <strong>zagVR</strong>
      </p>
    </div>
      <div id="top-layer">
        <div id="main-header-content">
          <div id="left-side">
            <div className="left-button-container">
              <Link to="collections">
                <Button icon labelPosition='left'><Icon name='archive'/>Collections</Button>
              </Link>
            </div>
            <div className="left-button-container">
              <Link to="new-collection">
                <Button icon labelPosition='left'><Icon name='add'/>New Collection</Button>
              </Link>
            </div>
          </div>
          <div id="right-side">
            <div id="name-container" className="name-text">
              <p>
                <strong>Leyton Blackler</strong><br/>Datacom</p>
            </div>
            <div className="right-button-container">
              <Button disabled icon labelPosition='left'><Icon name='user circle'/>Manage Account</Button>
            </div>
            <div id="right-button-container">
              <Button disabled icon labelPosition='left'><Icon name='log out'/>Log Out</Button>
            </div>
          </div>
        </div>
      </div>
      <div id="particles-layer">
        {this.renderParticles()}
      </div>
    </div>)
  }
}

export default windowSize(Header);
