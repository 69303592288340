import React, {Component} from 'react';
import './Popup.css'
import {Button, Modal, Form, TextArea, Icon, Image, TransitionablePortal} from 'semantic-ui-react'
import windowSize from 'react-window-size';

class Popup extends Component {

  componentDidMount() {
    document.body.classList.add('modal-fade-in');
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-fade-in')
  }

  render() {
    const styles = {
      width: this.props.width,
      height: this.props.height,
      top: (this.props.windowHeight / 2) - (this.props.height / 2),
      left: (this.props.windowWidth / 2) - (this.props.width / 2),
      marginLeft: 0
    };

    return (
      <span>
        {this.props.renderTriggerButton()}
        <TransitionablePortal open={this.props.showPopup} transition={{ animation:'scale', duration: 300 }}>
            <Modal style={styles} size="small" open={true}>
              <Modal.Header>{this.props.header}</Modal.Header>
              {this.props.renderContent ? <Modal.Content>{this.props.renderContent()}</Modal.Content> : ""}
              <Modal.Actions>
                {this.props.renderActions()}
              </Modal.Actions>
            </Modal>
            </TransitionablePortal>
    </span>

        )}
}

export default windowSize(Popup);
