import React, {Component} from 'react';
import './MainContent.css'
import { Form, Button } from 'semantic-ui-react';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom'

import { TransitionGroup, CSSTransition } from "react-transition-group";

import CollectionsPage from './pages/collections-page/CollectionsPage'
import NewCollectionPage from './pages/new-collection-page/NewCollectionPage'
import ManageScenesPage from './pages/manage-scenes-page/ManageScenesPage'
import ManageLinksPage from './pages/manage-links-page/ManageLinksPage'
export default class MainContent extends Component
{

    render(){
      console.log(window.location.pathname);
        return(
          <div id="main-content-container">
            <Redirect from="/" to="/collections" />
            <Switch>
              <Route path="/collections" component={ CollectionsPage }/>
              <Route path="/new-collection" component={ NewCollectionPage }/>
              <Route path="/manage-scenes" component={ ManageScenesPage }/>
              <Route path="/manage-links" component={ ManageLinksPage }/>
            </Switch>
          </div>
        )
    }
}
