import axios from 'axios';

// const baseURL = 'http://localhost:4002/api';
const baseURL = 'https://zageditorapi.marscloud.co.nz/api';

const api = {
  createCollection: (collection) => {

    console.log('creating...', collection);
    const data = new FormData();
    data.append('collection[name]', collection.name);
    data.append('collection[description]', collection.description);
    if (collection.image) {
      data.append('collection[image]', collection.image);
    }

    return axios({
      method: 'post',
      url: baseURL + '/collections',
      data: data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });
  },

  getCollections: () => {
    return axios.get(baseURL + '/collections');
  },

  deleteCollection: collectionID => {
    return axios.delete(baseURL + '/collections/' + collectionID);
  },

  updateCollection: collection => {
    const data = new FormData();
    data.append('collection[name]', collection.name);
    data.append('collection[description]', collection.description);
    if (collection.image) {
      data.append('collection[image]', collection.image);
    }

    return axios({
      method: 'put',
      url: baseURL + '/collections/' + collection.id,
      data: data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });
  },

  getScenes: (collectionID) => {
    console.log("calling", baseURL + '/collections/' + collectionID + '/scenes');
    return axios.get(baseURL + '/collections/' + collectionID + '/scenes');
  },



  //=============================== Scenes =====================================
  createScene: (scene, collectionID) => {
    const data = new FormData();
    data.append('scene[name]', scene.name);
    if (scene.image) {
      data.append('scene[image]', scene.image);
    }

    return axios({
      method: 'post',
      url: baseURL + '/collections/' + collectionID + '/scenes',
      data: data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });
  },

  getScenes: (collectionID) => {
    console.log("calling", baseURL + '/collections/' + collectionID + '/scenes');
    return axios.get(baseURL + '/collections/' + collectionID + '/scenes');
  },

  deleteScene: (sceneID, collectionID) => {
    return axios.delete(baseURL + '/collections/' + collectionID + '/scenes/' + sceneID);
  },

  updateScene: (scene, collectionID) => {
    const data = new FormData();
    data.append('scene[id]', scene.id);
    data.append('scene[name]', scene.name);
    if (scene.image) {
      data.append('scene[image]', scene.image);
    }

    return axios({
      method: 'put',
      url: baseURL + '/collections/' + collectionID + '/scenes/' + scene.id,
      data: data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });
  },




  //============================== Navigations =================================
  createNavigation: (navigation, sceneID, collectionID) => {
    const data = new FormData();

    data.append('navigation[name]', navigation.name);
    data.append('navigation[to_scene_id]', navigation.toSceneID);
    data.append('navigation[x]', navigation.position.x);
    data.append('navigation[y]', navigation.position.y);

    return axios({
      method: 'post',
      url: baseURL + '/collections/' + collectionID + '/scenes/' + sceneID + '/navigations',
      data: data,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    });
  },

  getNavigations: (sceneID, collectionID) => {
    return axios.get(baseURL + '/collections/' + collectionID + '/scenes/' + sceneID + '/navigations');
  },

  deleteNavigation: (navigationID, sceneID, collectionID) => {
    return axios.delete(baseURL + '/collections/' + collectionID + '/scenes/' + sceneID + '/navigations/' + navigationID);
  },

  updateNavigation: (navigation, sceneID, collectionID) => {
    const data = new FormData();

    data.append('navigation[name]', navigation.name);
    data.append('navigation[x]', navigation.position.x);
    data.append('navigation[y]', navigation.position.y);

    return axios({
      method: 'put',
      url: baseURL + '/collections/' + collectionID + '/scenes/' + sceneID + '/navigations/' + navigation.id,
      data: data,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    });
  },
}

export default api;
