import React, {Component} from 'react';
import './SceneListItem.css'
import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox,
  Dimmer,
  Header,
  Label
} from 'semantic-ui-react'
import {connect} from "react-redux";
import {setSelectedScene} from "../../../../../../redux/actions/index";

const DEFAULT_SCENE_IMAGE = "/images/default_scene_image.jpg";

class SceneListItem extends Component {

  listItemClicked = () => {
    this.props.setSelectedScene(this.props.scene);
  }

  scenePreviewClicked = event => {
    //Stop the click event propagating to the parent which would result in unintentionally selecting the scene.
    event.stopPropagation();
  }

    render(){
      console.log("scene", this.props.scene);
        return(
          <List.Item onClick={this.listItemClicked}>
            <List.Content>
              <div className="list-item-content" id={this.props.selected ? "selected-list-item" : ""}>
                <div id="scene-preview-container" onClick={this.scenePreviewClicked}>
                  <div id="scene-preview-hover">
                    <Icon id="enlarge-icon" name='expand'/>
                  </div>
                  <Image id="scene-preview" src={this.props.scene.image ? this.props.scene.image : DEFAULT_SCENE_IMAGE}/>
                </div>
                <div className="scene-row-text">
                  <strong>Name: </strong>{this.props.scene.name}</div>
                <div className="scene-row-text">
                  <strong>Links: </strong>0</div>
                <div className="scene-row-text">
                  <strong>Info points: </strong>0</div>
                <div className="scene-row-text">
                  <strong id="map-location-set-label">Map location set:</strong><Checkbox readOnly/></div>
                  {this.props.entryScene ? <Label color="blue">Entry Scene</Label> : null}
              </div>
            </List.Content>
          </List.Item>
        )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedScene: scene => dispatch(setSelectedScene(scene))
  }
}

export default connect(null, mapDispatchToProps)(SceneListItem)
