import React, {Component} from 'react';
import './EditSceneDetailsPopup.css'
import {Button, Modal, Form, TextArea, Icon, Image, TransitionablePortal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {updateScene} from "../../../../../../redux/actions/index";
import SceneForm from '../../../../forms/scene-form/SceneForm'
import Popup from '../../../../common/popup/Popup'

class EditSceneDetailsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }
  }

  onSubmit = (scene) => {
    this.props.updateScene(scene, this.props.currentCollection).then(() => {
      this.setState({
        ...this.state,
        showPopup: false
      });
    });
  }

  handleOpen = () => {
    this.setState({ showPopup: true })
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  }

  renderTriggerButton = () => {
    return <Button disabled={this.props.disabled} icon labelPosition='left' onClick={this.handleOpen}><Icon name='edit'/>Edit Details</Button>
  }

  renderContent = () => {
    console.log('render content', this.props.scene);
    return <span>
      <SceneForm scene={this.props.scene} onSubmit={this.onSubmit} editMode={true}/>
    </span>
  }

  renderActions = () => {
    return <span>
      <span className="action-button-container">
        <Button icon labelPosition='left' onClick={this.handleClose}><Icon name='cancel'/>Cancel</Button>
        </span>
        <span className="action-button-container">
        <Button icon labelPosition='left' type="submit" form="scene-form"><Icon name='save'/>Save</Button>
        </span>
      </span>
  }

  render() {
    return (
      <Popup
        width={800}
        height={435}
        showPopup={this.state.showPopup}
        header="Edit Details"
        renderTriggerButton={this.renderTriggerButton}
        renderContent={this.renderContent}
        renderActions={this.renderActions}></Popup>
        )}
}

const mapDispatchToProps = dispatch => {
  return {
    updateScene: (scene, collection) => dispatch(updateScene(scene, collection))
  }
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSceneDetailsPopup)
