import React, {Component} from 'react';
import { Redirect } from 'react-router'
import './Scenes.css'
import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox
} from 'semantic-ui-react'
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from "react-redux";
import { getScenes, setSelectedScene } from "../../../../../redux/actions/index";
import SceneListItem from './scene-list-item/SceneListItem'
import InfoMessage from '../../../common/info-message/InfoMessage'

class Scenes extends Component
{

  constructor(props) {
    super(props);
    if (!props.currentCollection) { return }
    this.props.getScenes(props.currentCollection);
    this.renderContent = this.renderContent.bind(this);
  }

  renderThumbVertical = ({ style, ...props }) => {
     return <div {...props} style={{ ...style, backgroundColor: '#e0e1e2', width: '8px', borderRadius: '10px', cursor: 'pointer'}}/>
  }

  renderContent = () => {
    console.log(this.props.scenesLoaded ? "scenes loaded" : "scenes not loaded");
    if (this.props.scenesLoaded) {
      if (this.props.scenes.length === 0) {
        return <div id="no-scenes-message-container"><InfoMessage>There are no existing scenes.</InfoMessage></div>
      } else {
        return <div id="scroll-area-container">
          <Scrollbars id="scroll-area" hideTracksWhenNotNeeded={true} renderThumbVertical={this.renderThumbVertical}>
            <List divided relaxed>
              {this.props.scenes.map((scene, index) => <SceneListItem entryScene={index === 0} key={scene.id} scene={scene} selected={this.props.selectedScene.id === scene.id}/>)}
            </List>
          </Scrollbars>
        </div>
      }
    }
  }

    render() {
        return(
          <Segment id="scenes-segment">
            <div className="segment-title-container">
              <h4>Scenes</h4>
            </div>
            {this.renderContent()}
          </Segment>
        )
    }
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection,
    scenesLoaded: state.scenesLoaded,
    scenes: state.scenes,
    selectedScene: state.selectedScene
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getScenes: collection => dispatch(getScenes(collection)),
    setSelectedScene: scene => dispatch(setSelectedScene(scene))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenes)
