import React, { Component } from 'react';

import styles from './ManageLinksPage.module.css';

import { Redirect } from 'react-router';

import { connect } from "react-redux";

import Title from '../../common/title/Title';
import ManageLinks from './manage-links/ManageLinks';
import BreadcrumbBar from '../../common/breadcrumb-bar/BreadcrumbBar';

class ManageLinksPage extends Component {

  render() {
    // If the manage scenes page is accessed without there being a current collection or selected scene, redirect back to the collections page.
    if (!this.props.currentCollection || !this.props.selectedScene) {
      return <Redirect to='/collections' />
    }

    return (
      <div className={styles.ManageLinksPage}>
        <Title text="Manage Links"/>
        <div className={styles.BreadcrumbBarContainer}>
          <BreadcrumbBar />
        </div>
        <ManageLinks />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection,
    selectedScene: state.selectedScene
  };
};

export default connect(mapStateToProps, null)(ManageLinksPage)


// <div className={styles.ManageLinksPage}>
//   <Title text="Manage Links"/>
//   <div className={styles.BreadcrumbBarContainer}>
//     <BreadcrumbBar />
//   </div>
//   <ManageLinks />
// </div>
