import React, {Component} from 'react';
import { FilePicker } from 'react-file-picker'
import './CollectionForm.css'

import {
  Form,
  Button,
  TextArea,
  Dropdown,
  Dimmer,
  Loader,
  Icon,
  Image,
  Segment,
  Input,
  Message
} from 'semantic-ui-react';

const tagOptions = [
  {
    key: 'Historical',
    value: 'Historical',
    text: 'Historical'
  }, {
    key: 'New Zealand',
    value: 'New Zealand',
    text: 'New Zealand'
  }
];

const DEFAULT_COVER_IMAGE = "/images/default_collection_cover_image.jpg";

export default class CollectionForm extends Component {

  constructor(props) {
    super(props);
    const emptyCollection = {
      name: "",
      description: "",
      tags: [],
      image: DEFAULT_COVER_IMAGE
    };
    this.state = {
      newImage: null,
      collection: this.props.collection ? this.props.collection : emptyCollection,
      submitted: false,
      errorMessages: {
        imageErrorMessage: null
      }
    };

    //Bind the form change and submit functions so that this.state can be accessed in the functions.
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, data) {
    console.log("Change:");
    console.log(data);
    console.log(data.value);
    this.setState({collection: {...this.state.collection, [data.id]: data.value}});
  }

  handleSubmit(event) {
    this.setState({submitted: true});
    this.props.onSubmit({...this.state.collection, image: this.state.newImage});
  }

  renderLoadingScreen = () => {
    if (this.state.submitted) {
      return (<Dimmer active id="dimmer">
        <Loader>{this.props.editMode ? "Saving collection" : "Creating collection"}</Loader>
      </Dimmer>)
    }
  }

  renderSubmitButton = () => {
    return <div className='action-button-container'><Button type="submit" className='action-button' icon labelPosition='left'><Icon name="add"/>Create</Button></div>
  }

  renderButtons = () => {
    if (!this.props.editMode) {
      return <div id="action-buttons-container" className="form-item">
        {this.renderSubmitButton()}
      </div>
    }
  }

  handleImageChange = (image) => {
    this.setState({
      newImage: image
    });
  }

  renderErrorMessage = (errorMessage) => {
    if (errorMessage) {
      return <Message color='red' id="imageErrorMessage">{errorMessage}</Message>
    }
  }

  render() {
    return (
      <Form id="collection-form" autoComplete="off" onSubmit={this.handleSubmit}>

        {this.renderLoadingScreen()}

        <Form.Field className="form-item" id="name-field">
          <label className="text">Name</label>
          <Input id="name" autoComplete="off" value={this.state.collection.name} onChange={this.handleChange}/>
        </Form.Field>
        <Form.Field className="form-item">
          <label className="text">Description</label>
          <TextArea id="description" value={this.state.collection.description ? this.state.collection.description : ""} onChange={this.handleChange}/>
        </Form.Field>
        <Form.Field className="form-item">
          <label className="text">Tags</label>
          <Dropdown id="tags" fluid multiple selection options={tagOptions} value={this.state.collection.tags} onChange={this.handleChange}/>
        </Form.Field>
        <Form.Field className="form-item">
          <label className="text">Cover Image</label>
          <div id="image-upload-container">
            <div id="image-picker-container">
            <FilePicker
              id="image-picker"
              extensions={['jpg', 'jpeg', 'png']}
              dims={{minWidth: 100, maxWidth: 20000, minHeight: 100, maxHeight: 20000}}
              maxSize={10}
              onChange={image => this.handleImageChange(image)}
              onError={errorMessage => this.setState({errorMessages: { imageErrorMessage: errorMessage }})}>
              <Button type="button" icon labelPosition='left'><Icon name='folder open'/>Choose Image</Button>
            </FilePicker>
            {this.renderErrorMessage(this.state.errorMessages.imageErrorMessage)}
            </div>
            <Dimmer.Dimmable id="cover-image-preview-container" dimmed={false}>
              <Dimmer active={false} inverted>
                <Loader>Uploading image</Loader>
              </Dimmer>
              <Image id="cover-image-preview" src={this.state.newImage? URL.createObjectURL(this.state.newImage) : this.state.collection.image? this.state.collection.image : DEFAULT_COVER_IMAGE}/>
            </Dimmer.Dimmable>
          </div>
        </Form.Field>
        {this.renderButtons()}
      </Form>)
  }
}
