import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styles from './LinkSelection.module.css';
import { Segment } from 'semantic-ui-react';
import ReactCursorPosition from 'react-cursor-position';
import ImageContainer from './image-container/ImageContainer';
import InfoMessage from '../../../../common/info-message/InfoMessage';
import windowSize from 'react-window-size';
import { connect } from "react-redux";
import { updateLink, setSaving } from "../../../../../../redux/actions/index";
import SelectorLine from './selector-line/SelectorLine';

class LinkSelection extends Component {

  constructor(props) {
    super(props);
    this.fromImageContainer = React.createRef();
    this.toImageContainer = React.createRef();
  }

  handleFromImageClick = position => {
    const newLink = {...this.props.selectedLink};
    newLink.from.position = {
      x: Math.round(position.x),
      y: Math.round(position.y)
    }
    this.props.setSaving();
    this.props.updateLink(this.props.selectedLink, this.props.currentCollection);
  }

  handleToImageClick = position => {
    const newLink = {...this.props.selectedLink};
    newLink.to.position = {
      x: Math.round(position.x),
      y: Math.round(position.y)
    }
    this.props.setSaving();
    this.props.updateLink(this.props.selectedLink, this.props.currentCollection);
  }

  render() {
    return this.props.linksLoaded ? (<div className={styles.LinkSelection}>
    <Segment id="link-selection" className={styles.RootImageContainer}>
      <div className={styles.SegmentContent}>
      <h4>Configure Link Points</h4>

        { this.props.selectedLink ? (
          <React.Fragment>

              <ImageContainer
                scene={this.props.selectedLink.from.scene}
                linkPosition={this.props.selectedLink.from.position}
                clickHandler={this.handleFromImageClick}/>

            <div className={styles.ImageSeparator} />

              <ImageContainer
                scene={this.props.selectedLink.to.scene}
                linkPosition={this.props.selectedLink.to.position}
                clickHandler={this.handleToImageClick}/>

          </React.Fragment>
        ) : (
          <div className={styles.InfoMessageContainer} style={{ width: 0.75 * this.props.windowHeight }}>
            <InfoMessage>To configure link points, please select a link in the list to the left.</InfoMessage>
          </div>
          )
        }

      </div>
    </Segment>

  </div>) : null
  }
};

const mapStateToProps = state => {
  return {
    selectedLink: state.selectedLink,
    linksLoaded: state.linksLoaded,
    currentCollection: state.currentCollection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLink: (link, collection) => dispatch(updateLink(link, collection)),
    setSaving: () => dispatch(setSaving())
  }
}

export default windowSize(connect(mapStateToProps, mapDispatchToProps)(LinkSelection));
