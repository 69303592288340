import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import styles from './SelectorLine.module.css';

import windowSize from 'react-window-size';

class SelectorLine extends Component {

  state = {
    loaded: false,
    originFrom: {
      x: 0,
      y: 0
    },
    originTo: {
      x: 0,
      y: 0
    }
  };

  componentDidMount() {
    if (this.props.fromRef.current && this.props.toRef.current) {
      const fromBounds = ReactDOM.findDOMNode(this.props.fromRef.current).getBoundingClientRect();
      const toBounds = ReactDOM.findDOMNode(this.props.toRef.current).getBoundingClientRect();
      this.setState({
        loaded: true,
        originFrom: {
          x: fromBounds.x,
          y: fromBounds.y
        },
        originTo: {
          x: toBounds.x,
          y: toBounds.y
        }
      });
    }
  }

  componentDidUpdate() {
    if (this.props.fromRef.current && this.props.toRef.current) {
      const fromBounds = ReactDOM.findDOMNode(this.props.fromRef.current).getBoundingClientRect();
      const toBounds = ReactDOM.findDOMNode(this.props.toRef.current).getBoundingClientRect();
      this.setState({
        loaded: true,
        originFrom: {
          x: fromBounds.x,
          y: fromBounds.y
        },
        originTo: {
          x: toBounds.x,
          y: toBounds.y
        }
      });
    }
  }

  render() {
    console.log('STATE', this.state);
    return this.state.loaded ? (
        <svg className={styles.SVG} width={this.props.windowWidth} height={this.props.windowHeight}>
          <line className={styles.Line}
          x1={this.state.originFrom.x + this.props.fromPosition.x}
          y1={this.state.originFrom.y + this.props.fromPosition.y}
          x2={this.state.originTo.x + this.props.toPosition.x}
          y2={this.state.originTo.y + this.props.toPosition.y}/>
          </svg>
        ) : null
  }
}

export default windowSize(SelectorLine);
