import React from 'react';

import styles from './Selector.module.css';

import { Icon } from 'semantic-ui-react';

const selector = (props) => {

  const position = {
    left: props.position.x - 15,
    top: props.position.y - 15
  };

  return (<div className={styles.Selector} style={position}>
    <Icon size="huge" name='crosshairs' className={styles.Crosshairs}/>
  </div>)
};

export default selector;
