import React, {Component} from 'react';
import './InfoMessage.css';
import {
  Icon
} from 'semantic-ui-react';

class InfoMessage extends Component
{
    render() {
        return(
            <div id="info-message-container">
              <Icon id="alert-icon" name='exclamation circle'/>
              <h3 id="info-message-text-container">{this.props.children}</h3>
            </div>
        )
    }
}

export default InfoMessage;
