import React, { Component } from 'react';

import styles from './GeneralOptions.module.css';

import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox
} from 'semantic-ui-react';

import { connect } from "react-redux";
import { createLink, setSelectedLink } from "../../../../../../redux/actions/index";

import NewLinkPopup from './new-link-popup/NewLinkPopup'

import { Redirect } from 'react-router';

class GeneralOptions extends Component {

  state = {
    toManageScenes: false
  };

  newLinkButtonPressed = () => {
    let link = {
      name: "MyNewLink",
      from: {
        scene: this.props.selectedScene,
        position: {
          x: 50,
          y: 50
        }
      },
      to: {
        scene: this.props.selectedScene,
        position: {
          x: 100,
          y: 100
        }
      }
    }
    this.props.createLink(link, this.props.currentCollection);
  }

  backToManageScenesButtonPressed = () => {
    this.setState({
      toManageScenes: true
    });
  }

  render() {

  if (this.state.toManageScenes) {
    return <Redirect to='/manage-scenes' />
  }

  return (
    <div className={styles.GeneralOptions}>
    <Segment className={styles.GeneralOptionsSegment}>
      <div className={styles.SegmentContent}>
        <h4>General</h4>
        <div>
          <NewLinkPopup />
        </div>
        <div className={styles.GeneralOptionsButtonSeparator}></div>
        <div>
          <Button disabled fluid icon labelPosition='left'><Icon name='question circle'/><div className="centred-button-text">Help</div></Button>
        </div>
        <div className={styles.GeneralOptionsButtonSeparator}></div>
        <div>
          <Button fluid icon labelPosition='left' onClick={this.backToManageScenesButtonPressed}><Icon name='arrow left'/><div className="centred-button-text">Back to Manage Scenes</div></Button>
        </div>
      </div>
    </Segment>
    </div>
  );
}
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection,
    selectedScene: state.selectedScene
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createLink: (link, scene, collection) => dispatch(createLink(link, scene, collection)),
    setSelectedLink: link => dispatch(setSelectedLink(link))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralOptions);
