import React, { Component } from 'react';

import styles from './ImageContainer.module.css';

import './ImageMapperStyle.css';

import classnames from 'classnames';

import { Segment, Image as SemanticImage } from 'semantic-ui-react';
import ReactCursorPosition from 'react-cursor-position';

import windowSize from 'react-window-size';
import ImageMapper from 'react-image-mapper';

import Selector from './selector/Selector';

import { connect } from "react-redux";

class ImageContainer extends Component {

  constructor(props) {
    super(props);

    this.currentSceneID = props.scene.id;

    this.updateScaling();
  }

  updateScaling = () => {
    // Get the size of the original image.
    const originalImage = new Image();
    originalImage.src = this.props.scene.image;
    this.originalImageSize = {
      width: originalImage.width,
      height: originalImage.height
    };

    // Calculate the width the images are permitted to be displayed at.
    this.displayedImageWidth = 0.75 * this.props.windowHeight;

    // Calculate the scale at which the image is displayed compared to the original stored image size.
    this.scale = this.displayedImageWidth / this.originalImageSize.width;

    console.log('scale = ' + this.displayedImageWidth + ' / ' + this.originalImageSize.width);

    console.log('id scale', this.props.scene.id, this.scale);

    // // Set the default selector position as centred.
    // this.setState = ({
    //   selectorPosition: {
    //     x: this.displayedImageWidth / 2,
    //     y: (this.originalImageSize.height * this.scale) / 2
    //   }
    // });
  }

  imageClicked = event => {
    // Divide offset by the amount at which the screen is scaled at (defined in App.css).
    const globalWindowScale = 0.8;

    const clickPosition = {
      x: event.nativeEvent.offsetX / globalWindowScale,
      y: event.nativeEvent.offsetY / globalWindowScale
    }

    const actualPosition = {
      x: clickPosition.x / this.scale,
      y: clickPosition.y / this.scale
    }

    this.props.clickHandler(actualPosition);
  }

  calculateSelectorPosition = () => ({
      x: this.props.linkPosition.x * this.scale,
      y: this.props.linkPosition.y * this.scale
    }
  );

  render() {
    if (this.currentSceneID !== this.props.scene.id) {
      this.currentSceneID = this.props.scene.id;
      this.updateScaling();
    }
    return <div className={classnames(styles.ImageContainer, 'ImageMapperContainer')}>
        <Selector position={this.calculateSelectorPosition()}/>
        <SemanticImage src={this.props.scene.image} onClick={this.imageClicked} style={
            {
              width: this.displayedImageWidth,
              minWidth: this.displayedImageWidth,
              maxWidth: this.displayedImageWidth
            }}/>
      </div>
  }
};

const mapStateToProps = state => {
  return {
    saving: state.saving
  };
};

export default windowSize(connect(mapStateToProps, null)(ImageContainer));
