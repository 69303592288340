import React from 'react';

import styles from './LinkListItem.module.css';

import {
  Button,
  Icon
} from 'semantic-ui-react';

import { connect } from "react-redux";
import { setSelectedLink, deleteLink } from "../../../../../../../redux/actions/index";
import DeletePopup from '../../../../../common/delete-popup/DeletePopup';
import EditLinkDetailsPopup from './edit-link-details-popup/EditLinkDetailsPopup';

const linkListItem = (props) => {

  const linkButtonClicked = () => {
    props.setSelectedLink(props.link);
  }

  const onDelete = () => {
    props.deleteLink(props.link, props.currentCollection);
  }

  return (
    <div className={styles.LinkListItem}>
      <Button fluid active={props.selectedLink.name === props.link.name} className={styles.LinkButton} onClick={() => linkButtonClicked(props.link)}>
        {props.link.name}
      </Button>
      <EditLinkDetailsPopup link={props.selectedLink}>
        <Button className={styles.Button} icon><Icon name="edit" /></Button>
      </EditLinkDetailsPopup>
      <DeletePopup
        subject="link"
        name={props.link ? props.link.name : ""}
        onDelete={onDelete}>
        <Button className={styles.Button} icon="trash" negative />
      </DeletePopup>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    selectedLink: state.selectedLink,
    currentCollection: state.currentCollection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedLink: link => dispatch(setSelectedLink(link)),
    deleteLink: (link, collection) => dispatch(deleteLink(link, collection)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(linkListItem);
