import React, {Component} from 'react';
import './ManageScenesPage.css'

import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox
} from 'semantic-ui-react'

import { Redirect } from 'react-router';
import { connect } from "react-redux";

import BreadcrumbBar from '../../common/breadcrumb-bar/BreadcrumbBar';
import Title from '../../common/title/Title';
import SceneOptions from './scene-options/SceneOptions';
import GeneralOptions from './general-options/GeneralOptions';
import Scenes from './scenes/Scenes';

class ManageScenesPage extends Component {

  render() {
    // If the manage scenes page is accessed without there being a current collection, redirect back to the collections page.
    if (!this.props.currentCollection) {
      return <Redirect to='/collections' />
    }
    return (
    <div id="manage-scenes-page-container">
      <Title text="Manage Scenes" />
      <div id="manage-scenes-segments-container">
        <div id="breadcrumb-bar-container">
          <BreadcrumbBar />
        </div>
        <div id="scenes-and-general-options-container">
          <div id="scene-options-and-scenes-container">
            <div id="scene-options-container">
              <SceneOptions></SceneOptions>
            </div>
            <Scenes></Scenes>
          </div>
          <div id="general-options-container">
            <GeneralOptions></GeneralOptions>
          </div>
        </div>
      </div>
    </div>)
    }
  }

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection
  };
};

export default connect(mapStateToProps, null)(ManageScenesPage);
