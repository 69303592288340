import React, {Component} from 'react';
import styles from './BreadcrumbBar.module.css'
import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox,
  Label,
  Breadcrumb
} from 'semantic-ui-react'
import { connect } from "react-redux";

import SavingStatus from './saving-status/SavingStatus'

class BreadcrumbBar extends Component {

  renderSavingStatus = () => {
    let label = "Saving changes...";
    let color = "orange";

    if (false) {
      let label = "Changes saved!";
      let color = "green";
    }
    if (false) {
      label = "Changes last saved x minutes ago.";
      color = "green";
    }
    return (
    <Label basic size="large" color={color}>
      {label}
    </Label>)
  };

  renderBreadcrumb = (label, name, prefixWithArrow) => {
    let breadcrumb = prefixWithArrow ? (
      <div className={"segment-title-container " + styles.BreadcrumbLabel}>
        <Icon style={{color: "rgba(0, 0, 0, 0.4)"}} name="chevron right" size="large"/>
      </div>
    ) : null;

    breadcrumb = (
      <React.Fragment>
        {breadcrumb}
        <Label basic size="large" className={styles.BreadcrumbLabel}>
          <strong>{label}</strong>
        </Label>
        <div className="segment-title-container">
        <h4>{name}</h4>
        </div>
      </React.Fragment>
    );
    return breadcrumb;
  };

    render() {
        return(
          <Segment>
            <div className={styles.SegmentContent}>
              <div className={styles.BreadcrumbContainer}>

                {this.props.currentCollection ? (
                  this.renderBreadcrumb("Collection", this.props.currentCollection? this.props.currentCollection.name : "", false)
                ) : null}

                {this.props.selectedScene ? (
                  this.renderBreadcrumb("Scene", this.props.selectedScene? this.props.selectedScene.name : "", true)
                ) : null}

                {this.props.selectedLink ? (
                  this.renderBreadcrumb("Link", this.props.selectedLink? this.props.selectedLink.name : "", true)
                ) : null}

              </div>
              <div className="segment-title-container">
                <SavingStatus />
              </div>
            </div>
        </Segment>
        )
    }
}

const mapStateToProps = state => {
  return {
    currentCollection: state.currentCollection,
    selectedScene: state.selectedScene,
    selectedLink: state.selectedLink
  };
};

export default connect(mapStateToProps, null)(BreadcrumbBar)
