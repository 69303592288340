import React, {Component} from 'react';
import './DeletePopup.css'
import {Button, Modal, Form, TextArea, Icon, Image, TransitionablePortal} from 'semantic-ui-react'
import {connect} from "react-redux";
import Popup from '../popup/Popup'

export default class DeletePopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleOpen = () => {
    this.setState({ showPopup: true })
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  }

  handleDelete(event) {
    this.props.onDelete();
    this.handleClose();
  }

  renderTriggerButton = () => {
    return this.props.children ? (
      <span onClick={this.handleOpen}>{this.props.children}</span>
    ) : (
      <Button disabled={this.props.disabled} icon labelPosition='left' color='red' onClick={this.handleOpen}><Icon name='trash'/>Delete</Button>
    );
  }

  renderActions = () => {
    return <span>
      <span className="action-button-container">
      </span>
        <Button icon labelPosition='left' onClick={this.handleClose}><Icon name='cancel'/>Cancel</Button>
        <span className="action-button-container">
        <Button icon labelPosition='left' color='red' onClick={this.handleDelete}><Icon name='trash'/>Delete</Button>
        </span>
      </span>
  }

  render() {
    return (
      <Popup
        width={650}
        height={126}
        showPopup={this.state.showPopup}
        header={"Are you sure you want to delete the " + this.props.subject + " \"" + this.props.name +  "\"?"}
        renderTriggerButton={this.renderTriggerButton}
        renderActions={this.renderActions}></Popup>
        )}
}
