import React, {Component} from 'react';
import './CollectionCard.css';
import {connect} from "react-redux";
import {setCurrentCollection, deleteCollection} from "../../../../../redux/actions/index";
import { Form, Button, Icon, Modal, Image, Header, Segment, Label, Transition } from 'semantic-ui-react';
import { Redirect } from 'react-router';
import EditCollectionPopup from '../edit-collection-popup/EditCollectionPopup';
import DeletePopup from '../../../common/delete-popup/DeletePopup';

const DEFAULT_COVER_IMAGE = "/images/default_collection_cover_image.jpg";

class CollectionCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      toManageScenes: false,
      collectionBeingDeleted: false
    }
    this.onDelete = this.onDelete.bind(this);
  }

  manageScenesButtonPressed = () => {
    this.props.setCurrentCollection(this.props.collection);
    this.setState({toManageScenes: true});
  }

  onDelete = (collection) => {
    this.setState({
      ...this.state,
      collectionBeingDeleted: true
    });
    this.props.deleteCollection(this.props.collection).then(() => {
      this.setState({
        ...this.state,
        collectionBeingDeleted: false
      });
    });
  }

    render(){
      //{this.props.collection.tags.map((tag) => <div className="tag-container"><Label tag>{tag}</Label></div>)}
      if (this.state.toManageScenes) {
        return <Redirect to='/manage-scenes' />
      }
        return(
          <div className="collection">
            <Image className="cover-image" src={this.props.collection.image ? this.props.collection.image : DEFAULT_COVER_IMAGE}/>
            <div className="main-collection-content">
              <div className="info-container">
                <div className="collection-text-container">
                  <p className="collection-title collection-text">{this.props.collection.name}</p>
                  <p className="collection-text">{this.props.collection.description}</p>
                </div>
              </div>
              <div className="footer-container">
                <div className="tags-container">
                  <div className="tag-container"><Label tag>Example Tag</Label></div>
                </div>
                <div className="button-container">
                  <Button icon labelPosition='left' onClick={this.manageScenesButtonPressed}><Icon name='image'/>Manage Scenes</Button>
                </div>
                <div className="button-container">
                  <EditCollectionPopup collection={this.props.collection}></EditCollectionPopup>
                </div>
                <div className="button-container">
                  <DeletePopup subject="collection" name={this.props.collection ? this.props.collection.name : ""} onDelete={this.onDelete}></DeletePopup>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentCollection: collection => dispatch(setCurrentCollection(collection)),
    deleteCollection: collection => dispatch(deleteCollection(collection))
  }
}

export default connect(null, mapDispatchToProps)(CollectionCard)
