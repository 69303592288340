import React, {Component} from 'react';
import './GeneralOptions.css'
import {
  Grid,
  Image,
  Segment,
  List,
  Button,
  Icon,
  Radio,
  Popup,
  Checkbox
} from 'semantic-ui-react'
import NewScenePopup from './new-scene-popup/NewScenePopup'

import { Redirect } from 'react-router';

export default class GeneralOptions extends Component {

  state = {
    toCollections: false
  };

  backToCollectionsButtonPressed = () => {
    this.setState({
      toCollections: true
    });
  }

  newSceneButtonPressed = () => {
    console.log("new scene button pressed");
  }

    render() {

      if (this.state.toCollections) {
        return <Redirect to='/collections' />
      }

        return(
          <Segment id="general-options-segment">
              <div className="segment-title-container">
                <h4>General</h4>
              </div>
              <div className="general-options-button-container">
                <NewScenePopup />
              </div>
              <div className="general-options-button-container">
                <Button disabled fluid icon labelPosition='left'><Icon name='map outline'/><div className="centred-button-text">Set Map Boundary</div></Button>
              </div>
              <div className="general-options-button-container">
                <Button disabled fluid icon labelPosition='left'><Icon name='map outline'/><div className="centred-button-text">View Map</div></Button>
              </div>
              <div className="general-options-button-container">
                <Button disabled fluid icon labelPosition='left'><Icon name='question circle'/><div className="centred-button-text">Help</div></Button>
              </div>
              <div className="general-options-button-container">
                <Button disabled fluid icon labelPosition='left'><Icon name='compass'/><div className="centred-button-text">Enter Experience</div></Button>
              </div>
              <div className="general-options-button-container">
                <Button fluid icon labelPosition='left'><Icon name='arrow left'/><div className="centred-button-text" onClick={this.backToCollectionsButtonPressed}>Back to Collections</div></Button>
              </div>
          </Segment>
        )
    }
}
