import React, {Component} from 'react';
import { FilePicker } from 'react-file-picker'
import './SceneForm.css'

import {
  Form,
  Button,
  TextArea,
  Dropdown,
  Dimmer,
  Loader,
  Icon,
  Image,
  Segment,
  Input,
  Message
} from 'semantic-ui-react'

const DEFAULT_SCENE_IMAGE = "/images/default_scene_image.jpg";

export default class SceneForm extends Component {

  constructor(props) {
    super(props);
    const emptyScene = {
      name: "",
      image: DEFAULT_SCENE_IMAGE
    };
    this.state = {
      newImage: null,
      scene: this.props.scene ? this.props.scene : emptyScene,
      submitted: false,
      errorMessages: {
        imageErrorMessage: null
      }
    };

    //Bind the form change and submit functions so that this.state can be accessed in the functions.
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, data) {
    console.log("Change:");
    console.log(data);
    console.log(data.value);
    this.setState({scene: {...this.state.scene, [data.id]: data.value}});
  }

  handleSubmit(event) {
    // If the form is being used to created a new scene (no scene was provided in props), require that newImage exists.
    if (!this.props.scene && !this.state.newImage) {
      this.setState({errorMessages: {imageErrorMessage: "An image must be chosen to create a scene."}});
    } else {
      this.setState({submitted: true});
      this.props.onSubmit({...this.state.scene, image: this.state.newImage});
    }
  }

  renderLoadingScreen = () => {
    if (this.state.submitted) {
      return (<Dimmer active id="dimmer">
        <Loader>{this.props.editMode ? "Saving scene" : "Creating scene"}</Loader>
      </Dimmer>)
    }
  }

  handleImageChange = (image) => {
    this.setState({
      newImage: image
    });
  }

  renderErrorMessage = (errorMessage) => {
    if (errorMessage) {
      return <Message color='red' id="imageErrorMessage">{errorMessage}</Message>
    }
  }

  render() {
    return (
      <Form id="scene-form" autoComplete="off" onSubmit={this.handleSubmit}>

        {this.renderLoadingScreen()}

        <Form.Field className="form-item" id="name-field">
          <label className="text">Name</label>
          <Input id="name" autoComplete="off" value={this.state.scene.name} onChange={this.handleChange}/>
        </Form.Field>
        <Form.Field className="form-item">
          <label className="text">Image</label>
          <div id="image-upload-container">
            <div id="image-picker-container">
            <FilePicker
              id="image-picker"
              extensions={['jpg', 'jpeg', 'png']}
              dims={{minWidth: 100, maxWidth: 20000, minHeight: 100, maxHeight: 20000}}
              maxSize={10}
              onChange={image => this.handleImageChange(image)}
              onError={errorMessage => this.setState({errorMessages: { imageErrorMessage: errorMessage }})}>
              <Button type="button" icon labelPosition='left'><Icon name='folder open'/>Choose Image</Button>
            </FilePicker>
            {this.renderErrorMessage(this.state.errorMessages.imageErrorMessage)}
            </div>
            <Dimmer.Dimmable id="scene-image-preview-container" dimmed={false}>
              <Dimmer active={false} inverted>
                <Loader>Uploading image</Loader>
              </Dimmer>
              <Image id="scene-image-preview" src={this.state.newImage? URL.createObjectURL(this.state.newImage) : this.state.scene.image? this.state.scene.image : DEFAULT_SCENE_IMAGE}/>
            </Dimmer.Dimmable>
          </div>
        </Form.Field>
      </Form>)
  }
}
