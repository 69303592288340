import React, { Component } from 'react';

import { Label } from 'semantic-ui-react'
import { connect } from "react-redux";

class SavingStatus extends Component {

  showSavingMessage = false;

   render() {
     let label = "Changes saved!";
     let color = "green";

     // Forces a minimum display time of 1 second for the "Saving changes..." message.
     if (this.props.saving && !this.showSavingMessage) {
       this.showSavingMessage = true;
       setTimeout(() => {
         this.showSavingMessage = false;
         this.forceUpdate();
       }, 1000);
     }

     if (this.props.saving || this.showSavingMessage) {
       label = "Saving changes...";
       color = "orange";
     }

     // if (false) {
     //   label = "Changes last saved x minutes ago.";
     //   color = "green";
     // }
     return (
     <Label basic size="large" color={color}>
       {label}
     </Label>)
   }
}

const mapStateToProps = state => {
  return {
    saving: state.saving
  };
};

export default connect(mapStateToProps, null)(SavingStatus)
