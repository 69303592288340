import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from "react-redux";
import store from "./redux/store/index";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom'

import Header from './components/header/Header'
import MainContent from './components/main-content/MainContent'

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div id="root-container">
            <div id="unsupported-resolution">Your resolution is too low to use the zagVR collection manager.</div>
            <div id="app">
              <Header></Header>
              <MainContent id="main-content"></MainContent>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
